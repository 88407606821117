<template>
    <div class="home-info">
        <div class="home-info-item">
            <div class="con-title flex-center">
                <img src="https://khome2.tuzuu.com/vita/icon_1.png?v=1" class="info">
                FFQs的生活｜工作｜饮食状态
            </div>
            <div class="con-desc">
                *哈佛FFQs发明者认为，FFQ的准确率约为60%
            </div>
        </div>
        <div class="home-info-item flex-between">
            <div class="char">+</div>
            <div>
                <div class="con-title flex-center">
                    <img src="https://khome2.tuzuu.com/vita/icon_5.png?v=1" class="info">
                    遗传因子｜基因分析｜营养代谢
                </div>
                <div class="con-desc">
                    *通过基因的精准科学，找到最适合你的营养方案
                </div>
            </div>

        </div>
        <div class="home-info-end flex-between">
            <div class="char">=</div>
            <div class="flex">
                <div class="char-1">专属于你的</div>
                <div class="char-2">
                    <span>健康管理营养包</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: ""
    }
</script>

<style scoped lang="less">
    .home-info{
        .home-info-item{
            margin-bottom: 20px;
            .con-title{
                font-size: 17px;
                color: #3F3F3F;
                justify-content: flex-end;
                font-weight: 700;
            }
            .info{
                width: 30px;
                margin-right: 2px;
            }
            .con-desc{
                font-size:12px;
                color: #3F3F3F;
                text-align: right;
                margin-top: 10px;
            }
        }
        .home-info-end{
            border-top:2PX solid #3F3F3F;
            margin-top: 10px;
            padding-top: 20px;
            font-weight: 700;

            .char-1{
                font-size:15px;
                color: #3F3F3F;
            }
            .char-2{
                font-size: 21px;
                color: #292C2F;
                margin-left: 15px;
                position: relative;
                &:before{
                    display: block;
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 7px;
                    bottom: 1px;
                    left: -4px;
                    padding: 0px 4px;
                    z-index: 1;
                    background-color: #6B00AF;
                }
                span{
                    position: relative;
                    z-index: 1;
                }
            }
        }
        .char{
            font-size: 24px;
            color: #3F3F3F;
            font-weight: 700;
        }
    }
</style>