<template>
    <div class="quality">
        <div class="qua-1">
            <img src="https://khome2.tuzuu.com/vita/ahome_1.png">
            <img src="https://khome2.tuzuu.com/vita/logo.png" class="logo">
            <div class="title">我们为什么不直接通过问卷提供方案</div>
        </div>

        <div class="qua-2">
            <div class="title">
                <p>我们理解现在通用的做法是透过膳食频率问卷法（FFQs）来获取您每日的营养摄取量，从而给您定制每日营养方案</p>
            </div>
            <div class="hr"></div>
            <div class="qua-item">
                <div class="item-title">膳食频率问卷法的优点：</div>
                <ul>
                    <li class="flex">它们可以较好的捕获偶尔或偶尔食用的食物的消费量；</li>
                    <li class="flex"> 它们容易大规模的应用于识字人群，同时也更容易 处理。</li>
                </ul>
            </div>
            <div class="qua-item" style="margin-top: 25px">
                <div class="item-title">但是，膳食频率问卷法也有很多缺点：</div>
                <ul>
                    <li class="flex">
                        FFQs缺乏关于如何准备食物的详细信息，也没有关于具体消耗多少和何时消耗的信息，如原本食物中的微量元素，大部分基本在烹饪的情况下会衰减甚至会消失，而FFQs无法应对此类情况；
                    </li>
                    <li class="flex">
                        由于FFQs仅限于固定的食物清单，因此它们无法捕捉不同的人的习惯；
                    </li>
                    <li class="flex">
                        FFQs更容易受到社会期望偏差的影响，人们会高估水果和蔬菜等“健康”食品的消费程度。
                    </li>
                </ul>
            </div>

            <div class="end-title">哈佛的FFQs发明者Walter Willett认为，FFQs的准确率仅为60-70%</div>
            <div class="end-title" style="font-weight: 400">因此，我们通过——</div>
            <HomeDesc />
            <div class="end-info" style="margin-top: 30px">基因检测是精准医学分析的一种方法，每个人的DNA基因都是独特的个人化资讯，这造成了每个人的先天体质，健康状况和特征都不相同，2008年，美国时代杂志曾把这个革命性的技术评选为2008年度最佳创新之首。</div>
            <div class="end-info" style="margin-bottom: 0px">因此即便是相同饮食习惯的人，因为基因的差异，可能导致个体的基础代谢速率不同，营养的吸收也不一样。</div>
        </div>
    </div>
</template>

<script>
    import HomeDesc from "../../components/HomeDesc";
    export default {
        name: "",
        components:{
            HomeDesc
        }
    }
</script>

<style scoped lang="less">
    .quality {
        letter-spacing: 0.5px;
        width: 100%;
        min-height: 100vh;
        background-color: #F2F2F2;
        font-size: 16px;
        padding-bottom: 30px;
        .qua-1 {
            position: relative;

            .title {
                position: absolute;
                font-size: 18px;
                font-weight: 700;
                color: white;
                top: -13px;
                bottom: 0;
                height: fit-content;
                margin: auto;
                width: 100%;
                text-align: center;
            }
        }

        .qua-2 {
            width: calc(100% - 20px);
            margin: auto;
            background-color: white;
            border-radius: 10px;
            margin-top: -60px;
            position: relative;
            z-index: 2;
            padding: 20px 15px 25px 15px;
            box-sizing: border-box;

            .title {
                font-size: 14px;
                color: #3F3F3F;
                line-height: 23px;
            }

            .hr {
                height: 1px;
                width: 100%;
                border-bottom: 1px solid #292C2F;
                margin-top: 15px;
                margin-bottom: 20px;
            }
        }

        .qua-item {
            margin-bottom: 17px;

            .item-title {
                font-size: 14px;
                color: #3F3F3F;
                margin-bottom: 20px;
                font-weight: 700;
            }

            ul {
                color: #7e7e7e;
                font-size: 14px;
                counter-reset: section;

                li {
                    align-items: end;
                    line-height: 25px;

                    &:before {
                        counter-increment: section;
                        content: counter(section) ".";
                    }

                }
            }
        }

        .end-title {
            font-size: 16px;
            color: #3f3f3f;
            line-height: 23px;
            font-weight: 700;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .end-info{
            font-size: 14px;
            color: #292C2F;
            line-height: 25px;
            margin-bottom: 20px;
        }

        img {
            width: 100%;
            display: block;
        }
    }
</style>